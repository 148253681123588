import Loading from 'quasar/src/plugins/Loading.js';;
import apollo from '@/library/http/apollo';
import gql from 'graphql-tag';

export const APP_ACTIONS = {
  SET_PRELOADING: 'setPreloading',
  SET_BREADCRUMBS: 'setBreadcrumbs',
  SET_PAGE_TITLE: 'setPageTitle',
  SET_METRICS: 'setMetrics',
  GET_USER_INFO: 'setUserInfo',
  SET_INFRA_SERVICE_OWNERS: 'setInfraServiceOwners',
  SET_INFRA_SERVICE_EDIT_GROUP: 'setInfraServiceEditGroup',
};

export const APP_GETTERS = {
  PRELOADING: 'preloading',
  BREADCRUMBS: 'breadcrumbs',
  PAGE_TITLE: 'pageTitle',
  METRICS: 'metrics',
  USER_INFO: 'userInfo',
  INFRA_SERVICE_AUTH_SETTINGS: 'infraServiceAuthSettings',
  USER_APPLICATIONS_COUNT: 'userApplicationsCount',
};

const APP_MUTATIONS = {
  CACHE_PRELOADING: 'cachePreloading',
  CACHE_BREADCRUMBS: 'cacheBreadcrumbs',
  CACHE_PAGE_TITLE: 'cachePageTitle',
  CACHE_METRICS: 'cacheMetrics',
  CACHE_USER_INFO: 'cacheUserInfo',
  CACHE_INFRA_SERVICE_OWNERS: 'cacheInfraServiceOwners',
  CACHE_INFRA_SERVICE_EDIT_GROUP: 'cacheInfraServiceEditGroup',
  CACHE_USER_APPLICATIONS_COUNT: 'cacheUserApplicationsCount',
};

export default {
  namespaced: true,
  state: {
    preloading: true,
    breadcrumbs: [],
    pageTitle: undefined,
    metrics: {
      services: undefined,
      applications: undefined,
      pavedRoad: undefined,
    },
    userInfo: {
      email: undefined,
    },
    infraServiceAuthSettings: {
      owners: [],
      isEditGroup: false,
    },
  },
  getters: {
    [APP_GETTERS.PRELOADING]: (state: any) => state.preloading,
    [APP_GETTERS.BREADCRUMBS]: (state: any) => state.breadcrumbs,
    [APP_GETTERS.PAGE_TITLE]: (state: any) => state.pageTitle,
    [APP_GETTERS.METRICS]: (state: any) => state.metrics,
    [APP_GETTERS.USER_INFO]: (state: any) => state.userInfo,
    [APP_GETTERS.INFRA_SERVICE_AUTH_SETTINGS]: (state: any) => state.infraServiceAuthSettings,
    [APP_GETTERS.USER_APPLICATIONS_COUNT]: (state: any) => state.userInfo.applicationsInOwn,
  },
  actions: {
    [APP_ACTIONS.SET_INFRA_SERVICE_OWNERS]: async ({ commit }: { commit: any }, owners: []) => {
      commit(APP_MUTATIONS.CACHE_INFRA_SERVICE_OWNERS, owners);
    },
    [APP_ACTIONS.SET_INFRA_SERVICE_EDIT_GROUP]: async ({ commit }: { commit: any }, flag: boolean) => {
      commit(APP_MUTATIONS.CACHE_INFRA_SERVICE_EDIT_GROUP, flag);
    },
    [APP_ACTIONS.SET_METRICS]: async ({ commit }: { commit: any }) => {
      await apollo.apolloClient
        .query({
          query: gql`
            query {
              homeMetrics {
                services
                applications
                adrs
              }
            }
          `,
        })
        .then((response) => {
          const metrics = response.data.homeMetrics;
          commit(APP_MUTATIONS.CACHE_METRICS, metrics);
        });
    },
    [APP_ACTIONS.SET_PAGE_TITLE]: async ({ commit }: { commit: any }, title: string) => {
      commit(APP_MUTATIONS.CACHE_PAGE_TITLE, title);
    },
    [APP_ACTIONS.SET_PRELOADING]: async ({ commit }: { commit: any }, flag: boolean) => {
      if (flag) {
        Loading.show();
      } else {
        Loading.hide;
      }
      commit(APP_MUTATIONS.CACHE_PRELOADING, flag);
    },
    [APP_ACTIONS.SET_BREADCRUMBS]: async ({ commit }: { commit: any }, breadcrumbs: Array<any>) => {
      commit(APP_MUTATIONS.CACHE_BREADCRUMBS, breadcrumbs);
    },
    [APP_ACTIONS.GET_USER_INFO]: async ({ commit }: { commit: any }) => {
      let userEmail;
      await apollo.apolloClient
        .query({
          query: gql`
            query {
              whoAmI
            }
          `,
        })
        .then((response) => {
          userEmail = response.data.whoAmI;
          commit(APP_MUTATIONS.CACHE_USER_INFO, userEmail);
        });

        await apollo.apolloClient
          .query({
            query: gql`
              query {
                userByEmail(email: "${userEmail}") {
                  email
                  applications
                }
              }
            `,
          }).then((response) => {
            commit(APP_MUTATIONS.CACHE_USER_APPLICATIONS_COUNT, response.data.userByEmail.applications.length);
          })

    },
  },
  mutations: {
    [APP_MUTATIONS.CACHE_METRICS]: (state: any, metrics: any) => {
      state.metrics = metrics;
    },
    [APP_MUTATIONS.CACHE_PAGE_TITLE]: (state: any, title: string) => {
      state.pageTitle = title;
    },
    [APP_MUTATIONS.CACHE_PRELOADING]: (state: any, status: boolean) => {
      state.preloading = status;
    },
    [APP_MUTATIONS.CACHE_BREADCRUMBS]: (state: any, breadcrumbs: Array<any>) => {
      state.breadcrumbs = breadcrumbs;
    },
    [APP_MUTATIONS.CACHE_USER_INFO]: (state: any, userEmail: object) => {
      state.userInfo.email = userEmail;
    },
    [APP_MUTATIONS.CACHE_USER_APPLICATIONS_COUNT]: (state: any, applicationsInOwn: object) => {
      state.userInfo.applicationsInOwn = applicationsInOwn;
    },
    [APP_MUTATIONS.CACHE_INFRA_SERVICE_OWNERS]: (state: any, owners: Array<string>) => {
      state.infraServiceAuthSettings.owners = owners;
    },
    [APP_MUTATIONS.CACHE_INFRA_SERVICE_EDIT_GROUP]: (state: any, flag: boolean) => {
      state.infraServiceAuthSettings.isEditGroup = flag;
    },
  },
};
