<template>
  <div class="single-page-markdown typ-layout">
    <h1>APM Key Details</h1>
    <table class="q-mb-md">
      <colgroup>
        <col style="width: 15.5465%" />
        <col style="width: 84.4674%" />
      </colgroup>
      <tbody>
        <tr>
          <td>Tech Lead</td>
          <td>
            <ul class="links q-px-none q-py-none q-mx-none q-my-none">
              <li class="q-mb-sm">
                <span style="text-transform: none">{{ apmDetails.itOwnerEmail }}</span>
              </li>
            </ul>
          </td>
        </tr>
        <tr>
          <td>Business Lead/PO</td>
          <td>
            <ul class="links q-px-none q-py-none q-mx-none q-my-none">
              <li class="q-mb-sm">
                <span style="text-transform: none"> {{ apmDetails.businessOwnerEmail }}</span>
              </li>
            </ul>
          </td>
        </tr>
        <tr>
          <td>APM Name</td>
          <td>
            <ul class="links q-px-none q-py-none q-mx-none q-my-none">
              <li class="q-mb-sm">
                <span style="text-transform: none">{{ dataService.displayName }}</span>
              </li>
            </ul>
          </td>
        </tr>
        <tr>
          <td>APM ID</td>
          <td>
            <ul class="links q-px-none q-py-none q-mx-none q-my-none">
              <li class="q-mb-sm">
                <span style="text-transform: none">{{ apmDetails.apmId }}</span>
              </li>
            </ul>
          </td>
        </tr>
        <tr>
          <td>Initial Release</td>
          <td>
            <ul class="links q-px-none q-py-none q-mx-none q-my-none">
              <li class="q-mb-sm">
                <span style="text-transform: none">{{ formatDate(apmDetails.createdAt) }}</span>
              </li>
            </ul>
          </td>
        </tr>
        <tr>
          <td>Programming language(s)</td>
          <!-- https://dev.azure.com/{org}/{project}/_apis/projectanalysis/languagemetrics -->
          <!-- DGF Question 271 (What tech stack do you use?) could be a fallback yet only has 4 options: Java/JEE, Javascript, Microsoft based, Other -->
          <td>Coming soon!</td>
        </tr>
        <tr>
          <td>System Type</td>
          <td>
            <ul class="links q-px-none q-py-none q-mx-none q-my-none">
              <li class="q-mb-sm">
                <span style="text-transform: none">{{ dataService.type }}</span>
              </li>
            </ul>
          </td>
        </tr>
        <tr>
          <td>Project Site</td>
          <td>
            <div v-if="dataService.links && dataService.links.length > 0">
              <a :href="dataService.links[0].url" target="_blank">
                <q-icon name="mdi-link-variant" class="q-mr-xs" size="xs" color="primary"></q-icon>
                {{ dataService.links[0].label || dataService.links[0].url }}
              </a>
            </div>
            <div v-else>
              <span>Temporary Unavailable</span>
            </div>
          </td>
        </tr>
        <tr>
          <td>Service Now</td>
          <td>Coming soon!</td>
        </tr>
        <tr>
          <td>Part of any shared service?</td>
          <td>Yes</td>
        </tr>
        <tr>
          <td>Associated Level 2 service</td>
          <td>N/A</td>
        </tr>
      </tbody>
    </table>
    <h1>Business Overview</h1>
    <div
      v-if="dataService.description && dataService.description.trim().length"
      class="single-page-markdown"
      v-html="dataMarkdown(extractFirstSection(dataService.description))"
    ></div>
    <h1>Responsibilities</h1>
    <p>Coming soon!</p>
    <h1>Current Release Information</h1>
    <p>Coming soon!</p>
    <h1>Collaborating Systems</h1>
    <p>Coming soon!</p>
    <h2>Systems We Depend On</h2>
    <p>Coming soon!</p>
    <h2>Systems We Enable</h2>
    <p>Coming soon!</p>
    <h1>Contracts</h1>
    <p>Coming soon!</p>
    <h2>Published API</h2>
    <p>Coming soon!</p>
    <h2>Events</h2>
    <p>Coming soon!</p>
    <h1>Architecture</h1>
    <p>Coming soon!</p>
    <h2>System/Application Architecture</h2>
    <p>Coming soon!</p>
    <h2>Technical/Platform Architecture</h2>
    <p>Coming soon!</p>
    <h2>Information Architecture</h2>
    <p>Coming soon!</p>
    <h2>Security Architecture</h2>
    <p>Coming soon!</p>
    <h2>Other Significant Architecture Perspectives</h2>
    <p>Coming soon!</p>
    <h2>Architecture Decision Records (ADRs)</h2>
    <p>Coming soon!</p>
    <h1>Developer Guides and Information</h1>
    <p>Coming soon!</p>
    <h2>Permissions</h2>
    <p>Coming soon!</p>
    <h2>Developing</h2>
    <p>Coming soon!</p>
    <h3>Repository Locations</h3>
    <p>Coming soon!</p>
    <h3>Local Development Environment</h3>
    <p>Coming soon!</p>
    <h3>Building &amp; Testing Locally</h3>
    <p>Coming soon!</p>
    <h3>Committing / Deploying The Solution</h3>
    <p>Coming soon!</p>
    <h3>Pipeline Location</h3>
    <p>Coming soon!</p>
    <h3>Code Level Documentation</h3>
    <p>Coming soon!</p>
    <h3>Non-Production Environments</h3>
    <p>Coming soon!</p>
    <h3>Production Environments</h3>
    <p>Coming soon!</p>
    <h1>Service Consumption</h1>
    <p>Coming soon!</p>
    <h2>Service Consumption Guide</h2>
    <p>Coming soon!</p>
    <h2>Service Budgeting/Cost</h2>
    <p>Coming soon!</p>
    <h1>Team Processes, Standards and Artifacts</h1>
    <p>Coming soon!</p>
    <h2>Backlog/Requirement Repository</h2>
    <p>Coming soon!</p>
    <h2>Issue Tracking System</h2>
    <p>Coming soon!</p>
    <h2>Team Operating Model</h2>
    <p>Coming soon!</p>
    <h2>Team Member Details</h2>
    <p>Coming soon!</p>
    <h2>Microsoft Teams Channel</h2>
    <p>Coming soon!</p>
    <h2>Project Web/Wiki Pages</h2>
    <p>Coming soon!</p>
    <p><span class="typ-version">TYP Template Version - 1.0.0-beta.7</span></p>
  </div>
</template>

<script>
import { marked } from 'marked';

export default {
  name: 'TechnicalYellowPage',
  props: {
    dataService: { type: Object, required: true },
    apmDetails: { type: Object, required: true },
  },
  computed: {
    projectOwnerContacts() {
      return this.dataService.contacts.filter((contact) => contact.label == 'PROJECT OWNER');
    },
    pals() {
      return this.dataService.contacts.filter((contact) => contact.label == 'PAL');
    },
  },
  methods: {
    dataMarkdown(data) {
      return data ? marked(data) : '';
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      if (isNaN(date.getTime())) {
        return 'N/A';
      }
      const day = date.getUTCDate();
      const month = date.toLocaleString('default', { month: 'long' });
      const year = date.getUTCFullYear();

      // Add suffix to day
      const daySuffix = (day) => {
        if (day > 3 && day < 21) return 'th'; // 11th, 12th, 13th
        switch (day % 10) {
          case 1:
            return 'st';
          case 2:
            return 'nd';
          case 3:
            return 'rd';
          default:
            return 'th';
        }
      };

      return `${day}${daySuffix(day)} of ${month} ${year}`;
    },
    extractFirstSection(markdown) {
      const lines = markdown.split('\n');
      let result = '';
      let capture = false;

      for (const line of lines) {
        if (line.startsWith('# ')) {
          if (capture) break;
          capture = true;
          continue; // Skip the first heading line
        }
        if (capture) {
          result += `${line}\n`;
        }
      }

      // If no heading was found, return the entire description
      if (!capture) {
        return markdown;
      }

      return result;
    },
  },
};
</script>

<style lang="scss" scoped>
table {
  width: 100%;
  border-collapse: collapse;
}

td {
  padding: 8px;
  border: 1px solid #ddd;
}

h1,
h2,
h3 {
  margin-top: 20px;
  margin-bottom: 10px;
}
.typ-layout {
  h2 {
    font-size: 20px;
  }
  h3 {
    font-size: 16px;
  }
  .typ-version {
    color: #a3a3a3;
  }
}
p {
  margin: 0;
}
</style>
